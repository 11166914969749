<template>
  <v-dialog
    v-model="isMerchantApiSettingsDialogOpen"
    max-width="650px"
    @click:outside="$emit('update:is-merchant-api-settings-dialog-open', false)"
  >
    <v-card class="user-edit-info pa-sm-10 pa-3">
      <v-card-title class="justify-center text-h5"> Merchant Api </v-card-title>

      <v-card-text v-if="merchantApiSettings" class="mt-5">
        <v-form ref="form" v-model="valid" class="multi-col-validation" @submit.prevent="onSubmit">
          <v-row>
            <v-col cols="12" sm="12">
              <v-switch v-model="merchantApiSettings.isEnable" :label="`Enable merchant api?`"></v-switch>
            </v-col>
            <v-row v-if="merchantApiSettings.isEnable">
              <v-col cols="12" sm="6">
                <div class="d-inline-flex w-full">
                  <v-text-field
                    :disabled="true"
                    outlined
                    dense
                    v-model="merchantApiSettings.userName"
                    hide-details
                    label="Basic auth user name"
                    class="merchanto-api-user-name mb-2"
                  ></v-text-field>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        id='copy-auth-user-name-button'
                        v-bind="attrs"
                        v-on="on"
                        @click="copy(merchantApiSettings.userName)"
                        class="pl-3 pr-2"
                      >
                        {{ icons.mdiContentCopy }}
                      </v-icon>
                    </template>
                    <span>Copy</span>
                  </v-tooltip>
                </div>
              </v-col>
              <v-col cols="12" sm="6">
                <div class="d-inline-flex w-full">
                  <v-text-field
                    :type="showApiBasicAuthPassword ? 'text' : 'password'"
                    :disabled="true"
                    outlined
                    dense
                    v-model="merchantApiSettings.password"
                    hide-details
                    label="Basic auth password"
                    class="merchanto-api-user-password mb-2"
                  ></v-text-field>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" @click="showApiBasicAuthPassword = !showApiBasicAuthPassword" class="pl-3 pr-2">
                        {{ showApiBasicAuthPassword ? icons.mdiEye : icons.mdiEyeOff }}
                      </v-icon>
                    </template>
                    <span>Show Secret Key</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        id='copy-auth-password-button'
                        v-bind="attrs"
                        v-on="on"
                        @click="copy(merchantApiSettings.password)"
                        class="pl-3 pr-2"
                      >
                        {{ icons.mdiContentCopy }}
                      </v-icon>
                    </template>
                    <span>Copy</span>
                  </v-tooltip>
                </div>
              </v-col>
              <v-col cols="12">
                <v-btn id="generate-username-and-password-button" v-if="!merchantApiSettings.password" x-large block color="info" @click="generateBasicAuth()">Generate user name & password</v-btn>
                <v-btn id="regenerate-password-button" v-else x-large block color="info" @click="reGenerateBasicAuth()">Re-generate password</v-btn>
              </v-col>
              <v-col id='is-enable-v2-api-checkbox' cols="12" sm="12">
                <v-checkbox
                  v-model="merchantApiSettings.isUseV2Api"
                  label="Enable v2 API"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="12">
                <v-text-field
                  v-model="merchantApiSettings.webhook"
                  outlined
                  dense
                  type="text"
                  label="Webhook"
                  placeholder="Webhook"
                  hide-details="auto"
                  class="merchanto-api-user-webhook mb-2"
                ></v-text-field>
              </v-col>
              <v-col id='send-email-notification-checkbox' cols="12" sm="12">
                <v-checkbox
                  v-model="merchantApiSettings.isSendEmail"
                  label="Send email notification"
                ></v-checkbox>
                <v-text-field
                  v-if="merchantApiSettings.isSendEmail"
                  v-model="merchantApiSettings.additionalEmail"
                  outlined
                  dense
                  :rules="[validators.emailValidator]"
                  type="text"
                  label="Email"
                  placeholder="Email"
                  hide-details="auto"
                  class="merchanto-api-email-webhook mb-2"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-col cols="12" class="d-flex justify-center mt-3">
              <v-btn color="primary" class="me-3" type="submit" @click.prevent="onSubmit"> Submit </v-btn>

              <v-btn
                outlined
                color="secondary"
                @click.prevent="$emit('update:is-merchant-api-settings-dialog-open', false)"
              >
                Discard
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, watch } from '@vue/composition-api';
import store from '@/store';
import { emailValidator, required, passwordValidatorIf } from '@core/utils/validation';
import Vue from 'vue';
import { mdiContentCopy, mdiEye, mdiEyeOff } from '@mdi/js';
import { eventBus } from '@/main';

export default {
  props: {
    isMerchantApiSettingsDialogOpen: {
      type: Boolean,
      required: true,
    },
    companyId: {
      required: true,
    },
  },
  setup(props, { emit }) {
    const merchantApiSettings = ref({
      userName: null,
      password: null,
      isEnable: false,
      webhook: null,
      isSendEmail: false,
      additionalEmail: null,
      isUseV2Api: false,
    });

    const loadMerchantApiSettings = async companyId => {
      return await store.dispatch('chargebackMerchantApi/fetchMerchantApiSettingsByCompanyId', companyId);
    };

    const showApiBasicAuthPassword = ref(false);
    const valid = ref(false);
    const form = ref(null);

    const validate = () => {
      form.value.validate();
    };

    const onSubmit = () => {
      if (valid.value) {
        Vue.$confirm({
          message: 'Are you sure you want change API credentials?',
          button: {
            no: 'No',
            yes: 'Yes',
          },
          callback: confirm => {
            if (confirm) {
              store
                .dispatch('chargebackMerchantApi/updateMerchantApiSettingsByCompanyId', {
                  companyId: props.companyId,
                  merchantApiSettings: merchantApiSettings.value,
                })
                .then(() => {
                  eventBus.$emit('user.refetch');
                  emit('update:is-merchant-api-settings-dialog-open', false);
                })
                .catch(e => {
                  Vue.notify({
                    type: 'error',
                    title: 'Merchant api settings',
                    text: e.response.data.message,
                    duration: 5000,
                  });
                });
            }
          },
        });
      } else {
        validate();
      }
    };

    watch(
      () => props.isMerchantApiSettingsDialogOpen,
      async () => {
        merchantApiSettings.value = {
          userName: null,
          password: null,
          isEnable: false,
          webhook: null,
          isSendEmail: false,
          isUseV2Api: false,
          additionalEmail: null,
        };
        const merchantApiSettingsResponse = await loadMerchantApiSettings(props.companyId);
        if (merchantApiSettingsResponse) {
          merchantApiSettings.value = {
            ...merchantApiSettings.value,
            ...merchantApiSettingsResponse,
          };
        }
      },
    );

    const copy = (value) => {
      navigator.clipboard.writeText(value);
      Vue.notify({
        type: 'success',
        text: 'Successfully copied text',
      });
    };

    const generateBasicAuth = () => {
      merchantApiSettings.value.userName = `mrhnt_lg_${props.companyId}`;
      merchantApiSettings.value.password = Math.random().toString(36).slice(-10);
    };

    const reGenerateBasicAuth = () => {
      Vue.$confirm({
        message: 'Are you sure you want to regenerate API Basic Auth? All API implementations with old API Basic Auth will not work anymore!',
        button: {
          no: 'No',
          yes: 'Yes',
        },
        callback: confirm => {
          if (confirm) {
            generateBasicAuth();
          }
        },
      });
    };

    return {
      copy,
      generateBasicAuth,
      reGenerateBasicAuth,
      form,
      merchantApiSettings,
      showApiBasicAuthPassword,
      onSubmit,
      valid,
      validate,
      validators: { required, passwordValidatorIf, emailValidator },
      icons: {
        mdiContentCopy,
        mdiEye,
        mdiEyeOff,
      }
    };
  },
};
</script>
