<template>
  <div id="merchant-list">
    <user-add></user-add>
    <merchant-api-settings
      :is-merchant-api-settings-dialog-open.sync="isMerchantApiSettingsDialogOpen"
      :company-id="selectedCompanyId"
    >
    </merchant-api-settings>
    <v-card>
      <v-card-title>Merchants list</v-card-title>
      <v-row class="pa-2 ma-0">
        <!-- status filter -->
        <v-col cols="12" sm="3">
          <v-select
            class="merchanto-status-filter"
            v-model="logic.statusFilter.value"
            placeholder="Select Status"
            :items="statusOptions()"
            item-text="title"
            item-value="value"
            outlined
            dense
            clearable
            hide-details
          ></v-select>
        </v-col>
        <!-- merchants filter-->
        <v-col v-if='isAdmin' cols="12" sm="3">
          <v-autocomplete
            class="merchants-managers-filter"
            v-model="logic.managersFilter.value"
            :items="logic.admins.value"
            item-value="id"
            :item-text="(manager) => `${manager.firstName} ${manager.lastName}`"
            dense
            filled
            outlined
            clearable
            hide-details
            label="Select manager"
          >
          </v-autocomplete>
        </v-col>
      </v-row>

      <v-divider></v-divider>

      <!-- actions -->
      <v-row class="pa-2 ma-0">
        <!-- search -->
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="logic.searchQuery.value"
            placeholder="Search"
            outlined
            hide-details
            dense
            class="merchanto-search-query user-search"
          >
          </v-text-field>
        </v-col>

        <v-col cols="12" sm="6">
          <div class="d-flex align-center justify-end flex-wrap">
            <v-btn
              color="primary"
              class="add-new-merchant"
              @click.stop="logic.openUserAddModal()"
            >
              <v-icon>{{ icons.mdiPlus }}</v-icon>
              <span>Add New Merchant</span>
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <!-- table -->
      <v-data-table
        :options.sync="logic.options.value"
        :headers="logic.tableColumns"
        :items="logic.users.value"
        :item-class="() => 'user-item'"
        :server-items-length="logic.countUsers.value"
        :loading="logic.loading.value"
      >
        <!-- name -->
        <template #[`item.fullName`]="{ item }">
          <div class="d-flex align-center">
            <v-avatar
              :color="item.avatar ? '' : 'primary'"
              :class="item.avatar ? '' : 'v-avatar-light-bg primary--text'"
              size="32"
            >
              <span class="font-weight-medium">{{ avatarText(item.fullName) }}</span>
            </v-avatar>

            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{ name: 'merchant-view', params: { id: item.id } }"
                class="merchant-name text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.fullName }}
              </router-link>
            </div>
          </div>
        </template>

        <!-- email -->
        <template #[`item.email`]="{ item }">
          <div class="merchant-item-email">{{ item.email }}</div>
        </template>

        <!-- manager -->
        <template #[`item.manager`]="{ item }">
          <div v-if='item.manager' class="merchant-item-manager">{{ item.manager.firstName }} {{ item.manager.lastName }}</div>
        </template>

        <!-- companyName -->
        <template #[`item.companyName`]="{ item }">
          <div class="merchant-item-company-name">{{ item.companyName }}</div>
        </template>

        <!-- apiSetting -->
        <template #[`item.apiSetting`]="{ item }">
          <div class="merchant-item-api-setting">
            <v-icon
              v-if="item.company.apiSetting && item.company.apiSetting.isEnable"
              class="api-enabled-icon"
              color="success"
            >
              {{ icons.mdiCheck }}
            </v-icon>
            <v-icon
              class="api-disabled-icon"
              v-else
            >
              {{ icons.mdiClose }}
            </v-icon>
          </div>
        </template>

        <!-- status -->
        <template #[`item.status`]="{ item }">
          <v-edit-dialog :return-value.sync="item.status" large persistent @save="logic.updateStatus(item.id, logic.newStatus.value)">
            <v-chip
              small
              :color="resolveUserStatusVariant(item.status)"
              :class="`${resolveUserStatusVariant(item.status)}--text`"
              class="v-chip-light-bg font-weight-semibold text-capitalize"
            >
              {{ item.status }}
            </v-chip>
            <template #input>
              <div class="mt-4 title">Update Status</div>
              <v-select
                v-model="logic.newStatus.value"
                :items="statusOptions()"
                item-text="title"
                item-value="value"
                label="Status"
              ></v-select>
            </template>
          </v-edit-dialog>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{ item }">
          <v-menu bottom left>
            <template #activator="{ on, attrs }">
              <v-btn class="merchant-list-item-show-more-actions" icon v-bind="attrs" v-on="on">
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item class="merchant-list-item-detail" :to="{ name: 'merchant-view', params: { id: item.id } }">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Details</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item class="merchant-list-item-login-as-merchant" @click="loginAsMerchant(item.id)">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiLogin }}
                  </v-icon>
                  <span>Login as merchant</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                class="merchant-list-item-api-settings"
                @click="
                  selectedCompanyId = item.companyId
                  isMerchantApiSettingsDialogOpen = !isMerchantApiSettingsDialogOpen
                "
              >
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Api Settings</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                class="merchant-list-item-reset-2fa"
                @click="logic.reset2fa(item)"
              >
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiOnepassword }}
                  </v-icon>
                  <span>Reset 2fa</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import {
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiPlus,
  mdiFileDocumentOutline,
  mdiDeleteOutline,
  mdiAccountOutline,
  mdiLogin, mdiOnepassword, mdiClose, mdiCheck,
} from '@mdi/js';
import { ref } from '@vue/composition-api';
import { avatarText } from '@core/utils/filter';
import useUsers, { resolveUserStatusVariant, statusOptions, isAdmin } from '@/views/shared/users/useUsers';
import store from '@/store';
import MerchantApiSettings from '@/views/shared/users/merchant/_partials/MerchantApiSettings.vue';
import RolesEnum from '@/enums/roles.enum';
import UserAdd from '@/views/shared/users/_partials/UserAdd.vue';

export default {
  components: {
    UserAdd,
    MerchantApiSettings,
  },
  setup() {
    const logic = useUsers(RolesEnum.MERCHANT);

    const selectedCompanyId = ref(null);
    const isMerchantApiSettingsDialogOpen = ref(false);
    const isAddNewMerchantSidebarActive = ref(false);

    return {
      logic,
      isMerchantApiSettingsDialogOpen,
      isAddNewMerchantSidebarActive,
      selectedCompanyId,
      isAdmin,
      // icons
      icons: {
        mdiOnepassword,
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiAccountOutline,
        mdiLogin,
        mdiClose,
        mdiCheck,
      },
    };
  },
  methods: {
    avatarText,
    statusOptions() {
      return statusOptions;
    },
    resolveUserStatusVariant,
    async loginAsMerchant(id) {
      const token = await store.dispatch('auth/getLoginToken', id);

      const route = this.$router.resolve({
        name: 'dashboard',
        query: {
          action: 'login-as-merchant',
          token,
        },
      });

      window.open(route.href, '_blank');
    },
  },
};
</script>

<style lang="scss">
.merchants-managers-filter {
  > .v-input__control > .v-input__slot {
    background: transparent !important;
  }
  .v-label {
    font-size: 14px;
    color: rgba(94, 86, 105, 0.38);
  }
}
@import '@core/preset/preset/apps/user';
</style>
